import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import * as dayjs from 'dayjs'

import { useIntl } from 'gatsby-plugin-intl'
import media from '../../components/css/media'
import Layout from '../../components/common/layout'
import Container from '../../components/common/container'
import GoBackLink from './goBackLink'

const PostContainer = styled.div`
  padding: 1rem 0 0 0;

  h1.post-title {
    font-size: 2em;
    line-height: 40px;
    margin-top: 57px;
    padding: 0 25px;
  }

  ${media.desktop`
    h1.post-title {
      font-size: 60px;
      line-height: 1;
      text-align: left;
      margin-top: 57px;
    }
  `}

  .post-published-at {
    font-size: 1em;
    line-height: 1.5;
    margin-top: 15px;
    margin-bottom: 30px;
    padding: 0 25px;
  }

  ${media.desktop`
    .post-published-at{
      font-size:1.2em;
      margin-top: 1em;
      text-align: left;
    }
  `}

  .wp-featured-image {
    text-align: center;
    margin-top: 3rem;
  }

  .blog-img {
    height: auto;
    max-width: 100%;
  }

  .wp-post {
    text-align: left;
    padding: 0 25px;
    line-height: 1.6;

    a {
      &:link,
      &:visited {
        color: #c36;
      }
    }

    p {
      font-size: 1.1em;
      line-height: 1.6em;
      font-family: inherit;
      margin-bottom: 1em;

      img {
        margin: 0 auto;
        display: block;
      }
    }

    img {
      height: auto;
      max-width: 100%;
    }

    .elementor-widget-container {
      iframe {
        width: 78vw;
      }
    }

    a {
      color: #da0000;
    }

    strong {
      font-weight: 600;
    }

    img {
      &.size-full {
        text-align-last: center;
        margin: 0;
        width: 85vw;
        height: auto;
      }
    }

    .elementor-image {
      img {
        width: 100%;
        height: 100%;
      }
    }

    figure {
      margin-inline-start: 10px;
      margin-inline-end: 10px;

      img {
        height: auto;
        max-width: 100%;
      }
    }

    table {
      text-align: left;
      background-color: transparent;
      width: 100%;
      margin-bottom: 15px;
      font-size: 0.9em;
      border-spacing: 0;
      border-collapse: collapse;

      tr:nth-child(odd) {
        background-color: #f7f7f7;
      }

      td {
        padding: 8px;
        line-height: 1.5;
        border: 1px solid #ccc;
        vertical-align: middle;
      }
    }

    ${media.desktop`
      table {
        td {
          padding: 15px;
        }
    `}

    h2 {
      font-size: 1.4em;
    }
    h3 {
      font-size: 1.2em;
    }
    h4 {
      font-size: 1.1em;
    }

    ${media.desktop`
      h2 {
        font-size: 1.75em;
      }
      h3 {
        font-size: 1.5em;
      }
      h4 {
        font-size: 1.3em;
      }
    `}
  }
`

const Post = ({ pageContext }) => {
  const intl = useIntl()
  const post = pageContext.post[0]
  const featuredImageUrl = post.yoast_head_json.og_image[0].url

  return (
    <Layout>
      <Container>
        <PostContainer>
          <GoBackLink className='back-btn' to={`/${intl.locale}/blog/`}>
            <img
              className='back-btn-icon'
              src='https://kto-static.mobilt.io/media/btn-back.svg'
              alt='Back button'
            />
            {intl.formatMessage({ id: 'common.back' })}
          </GoBackLink>

          <h1 className='post-title'>{post.title.rendered}</h1>
          <div className='post-published-at'>
            {intl.formatMessage({ id: 'blogs.publishedOn' }) +
              ' ' +
              dayjs(post.date).format('DD.MM.YYYY')}
          </div>

          <div className='wp-featured-image'>
            <img
              className='blog-img'
              src={`${featuredImageUrl}`}
              alt={`${post.title.rendered}`}
            />
          </div>
          <div
            className='wp-post'
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          />
        </PostContainer>

        <Helmet>
          <title>{post.yoast_head_json.title}</title>

          {post.yoast_head_json.script && (
            <script>{post.yoast_head_json.script}</script>
          )}

          <script type='application/ld+json'>
            {JSON.stringify(post.yoast_head_json.schema)}
          </script>

          {Object.keys(post.yoast_head_json).map((row, i) => {
            switch (row) {
              case 'robots':
                return (
                  <meta
                    key={i}
                    name={row}
                    content={Object.values(post.yoast_head_json[row]).join(',')}
                  />
                )
              case 'og_description':
              case 'og_image':
              case 'og_locale':
              case 'og_title':
              case 'og_site_name':
              case 'og_url':
              case 'og_type':
                return (
                  <meta
                    key={i}
                    property={row}
                    content={
                      row === 'og_image'
                        ? post.yoast_head_json[row][0].url
                        : post.yoast_head_json[row]
                    }
                  />
                )
              case 'description':
                return (
                  <meta
                    key={i}
                    name={row}
                    content={post.yoast_head_json[row]}
                  />
                )
              default:
                return <script key={i}></script>
            }
          })}
        </Helmet>
      </Container>
    </Layout>
  )
}

export default Post
